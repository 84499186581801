Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.loginAPiEndPoint = "login/login";
exports.loginAPiMethod = "POST";
exports.loginApiContentType = "application/json";
exports.errorPasswordNotValid = "Password not valid.";
exports.placeHolderEmail = "Email";
exports.placeHolderPassword = "Password";
exports.labelHeader = "The reason we require login";
exports.btnTxtLogin = "LOG IN";
exports.labelRememberMe = "Remember me";
exports.btnTxtSocialLogin = "LOGIN WITH SOCIAL MEDIA";
exports.labelOr = "OR";
exports.placeHolderMobile = "Mobile";
exports.labelForgotPassword = "Forgot Password?";
exports.btnTxtEmailLogin = "LOGIN WITH EMAIL";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorMobileNoNotValid = "Mobile no. not valid.";
exports.errorTitle = "Error";
exports.labelTitle = "Log in";
exports.labelLogin = "Login";
exports.labelCreateAccount = "Create your Account";
exports.labelFirstName = "First Name";
exports.labelLastName = "Last Name";
exports.labelEmail = "Email Address";
exports.labelPassword = "Password";
exports.labelConfirmPassword = "Confirm Password";
exports.labelIndustrySector = "Industry Sector";
exports.labelDistrictLocalAuthority = "District/Local Authority";
exports.submit = "Submit";
exports.resetPassword = "Reset Password";
exports.code = "Code";
exports.oneCap = "At least one capital letter";
exports.oneLower = "At least one lowercase letter";
exports.oneNumber = "At least one number";
exports.minChars = "Minimum character length is 10 characters";
exports.codeExample = "Ex- 1234";
exports.resendCode = "Resend Code";
exports.privacyPolicyConsent = "I hereby consent to the Privacy Policy and Terms of Use with both ";
exports.privacyPolicy = "Privacy Policy";
exports.and = "and ";
exports.termsOfUse = "Terms of Use";
exports.fillFirstName = "Please fill in the First Name field";
exports.invalidEmail = "Invalid Email";
exports.fillLastName = "Please fill in the Last Name field";
exports.fillPassword = "Please fill in the Password field";
exports.fillCheckbox = "Plese fill the checkbox";
exports.fillEmail = "Please fill in the Email field";
exports.fillCode = "Please fill in the code field";
exports.invalidFirstName = "Invalid First Name";
exports.invalidLastName = "Invalid Last Name";
exports.userSignUp = '/bx_block_user/users/user_signup';
exports.userLogin = '/bx_block_user/users/user_login';
exports.userForgetPassword = "/bx_block_user/users/forgot_password";
exports.userResetPassword = "/bx_block_user/users/reset_password";
exports.resendCodeEndpoint = "/bx_block_user/users/resend_code";
exports.sureClose = "Are you sure you want to go close the app?";
exports.holdOn = "Hold on!";
exports.yes = "Yes";
exports.cancel = "Cancel";
// Customizable Area End
