import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CfReportSurveyResultController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start


  getWidth=(widthString:string)=>{
   const updated= widthString.split('%')
   const num=parseInt(updated[0])
   return num

  }

  

   dummyData=[{questionId:'123', question_type:'slider',question_title:'Rate',attributes:{max:105,min:70,avg:90}},
   {questionId:'125',answer:'Option 1',total:133, question_type:'radio',question_title:'Rate',attributes:{options:[{id:21,name:'Option1',total:'95%',opted:true},{id:22,name:'Option2',total:'88%',opted:false},{id:12,name:'Option3',total:'75%',opted:false},{id:12,name:'Option3',total:'95%',opted:false},{id:312,name:'Option4',total:'66%',opted:false},{id:312,name:'Option5',total:'21%',opted:false}]}},
   {questionId:'127', question_type:'text',question_title:'Question',attributes:{answer:'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'}},
   {questionId:'1200', question_type:'longText',question_title:'Question',attributes:{answer:'bsadbadbnmadjnbajnbjbn'}},
   {questionId:'1259',answer:'Option 1',total:133, question_type:'checkbox',question_title:'Rate',attributes:{options:[{id:21,name:'Option1',total:'95%',opted:true},{id:22,name:'Option2',total:'88%',opted:false},{id:12,name:'Option3',total:'75%',opted:false},{id:12,name:'Option3',total:'95%',opted:false},{id:312,name:'Option4',total:'66%',opted:false},{id:312,name:'Option5',total:'21%',opted:false}]}},
  ]
 
   options = ['Option 1', 'Option 2', 'Option 3', 'Option 4'];

   goBack=()=>{
    this.props.navigation.goBack()
   }

   navigateToSettings=()=>{
    this.props.navigation.navigate('Settings',{currentTab:2})
  }

  goToProfile=()=>{
    this.props.navigation.navigate('Settings',{currentTab:0})
  }

  getNumbers=(length:number)=>{
    const numbers = Array.from(Array(length+1).keys());
    return numbers
  }

  checkLastIndex=(index:number)=>{
    return index===this.dummyData.length-1
  }
 
  // Customizable Area End
}
