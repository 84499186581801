import React from "react";


  // Customizable Area Start
  // Customizable Area End


// Customizable Area Start



// Customizable Area End

import AutomaticCheckoutCalculation2Controller, {
  Props,
} from "./MobileAccountLoginController.web";

export default class AutomaticCheckoutCalculation2 extends AutomaticCheckoutCalculation2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <></>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  
};
// Customizable Area End