Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "CfReportSurveyResult";
exports.labelBodyText = "CfReportSurveyResult Body";

exports.btnExampleTitle = "CLICK ME";
exports.surveyReport = "Survey Report";
exports.results = 'Results';
exports.answer = 'Answer';
exports.yourAnswer = "Your Answer";
exports.total = "Total";

// Customizable Area End