Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.applicationJsonContentType = "application/json";
exports.getApiMethod = "GET";
exports.postApiMethod = "POST";
exports.putApiMethod = "PUT";
exports.deleteApiMethod = "DELETE";
exports.surveyApiEndPoint = "bx_block_surveys/surveys";
exports.surveyQuestionApiEndPoint = "bx_block_surveys/questions";
exports.surveyQuestionSubmissionsApiEndPoint = "bx_block_surveys/submissions";
exports.surveyCatalogue = "Survey Catalogue";
exports.save = "Save";
exports.submit = "Submit";
exports.next = "Next";
exports.question = "Question";
exports.outOf = "out of";
exports.minValue = "Min Value";
exports.medianValue = "Median Value";
exports.maxValue = "Max Value";
exports.answer = "Answer";
exports.getUserDetails = "bx_block_user/users/edit_user";
// Customizable Area End