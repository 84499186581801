import React from "react";

import {
  Container,
  Box,
  Typography,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
const SurveysTheme = createTheme({
  palette: {
    primary: {
      main: "#0000FF",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

import SurveysController, {
  Props, ISurveyLists
} from "./SurveysController.web";
// Customizable Area End


export default class Surveys extends SurveysController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {
      surveyLists
    } = this.state;
    return (
      <ThemeProvider theme={SurveysTheme}>
        <Container maxWidth={"sm"}>
        <Typography variant="h2" style={webStyle.PageHeading}>
                    Survey List
                </Typography>
                {
                    Array.isArray(surveyLists) && surveyLists.length === 0 ? (
                        <Typography style={webStyle.NoProductNofificationText}>No survey list found.</Typography>

                    ) : <>
                        {
                            surveyLists.map((survey: ISurveyLists, index: number | string) => (
                                <Box style={webStyle.listContainer} key={index}>
                                    <Box>
                                        <Link to={`SurveyAnswerSubmission/${survey.id}`} style={{ textDecoration: "none" }}>
                                            <Typography variant="h4" style={webStyle.surveyName} data-testid="goToSurveyDetailsPage">
                                                {survey?.attributes?.name}
                                            </Typography>
                                        </Link>

                                        <Typography style={webStyle.surveyDescription}>
                                            {survey?.attributes?.description}
                                        </Typography>
                                    </Box>
                                </Box>
                            ))
                        }
                    </>

                }
        </Container>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  PageHeading: {
            fontSize: '37px',
            color: 'rgba(51, 51, 51, 1)',
            fontWeight: 600,
            fontFamily: 'Montserrat-Medium',
        },
        listContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '3rem 1.5rem',
            margin: '2rem 0',
            background: "#ffffff",
            boxShadow: "0 0 18px 3px rgba(0,0,0,0.1)",
            borderRadius: "5px",
            gap: '10px',
        },
        surveyName: {
            fontSize: '34px',
            color: 'rgba(51, 51, 51, 1)',
            fontWeight: 600,
            fontFamily: 'Montserrat-Medium',
        },
        surveyDescription: {
            color: 'rgba(86, 86, 86, 1)',
            fontFamily: 'Montserrat-SemiBold',
            fontSize: '18px',
        },

        NoProductNofificationText: {
            color: '#FF3939',
            fontFamily: 'Montserrat-SemiBold',
            fontWeight: 500,
            fontSize: '23px',
        },
};
// Customizable Area End
